<template>
  <div class="pagecontainer">
    <background></background>
    <q-scroll-area
      class="full-width q-ma-none q-pa-none"
      style="
        width: 100vw;
        height: calc(100vh - 1px);
        top: 0;
        left: 0;
        position: fixed;
      "
    >
      <div style="margin-top: 50px">
        <router-view v-slot="{ Component }">
          <transition
            name="custom-classes-transition"
            enter-active-class="animate__animated animate__zoomInDown animate__slower"
            leave-active-class="animate__animated animate__fadeOutDown"
          >
            <component :is="Component"></component>
          </transition>
        </router-view>
      </div>
    </q-scroll-area>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
export default {
  name: "pagecontainer",
  components: {
    // MarqueeText: defineAsyncComponent(() => import("@/components/MarqueeText.vue"))
    Background: defineAsyncComponent(() =>
      import("@/views/mainlayout/components/Background.vue")
    ),
  },

  setup() {
    return {};
  },
};
</script>

<style lang="scss">
/* Enter and leave animations can use different */
/* durations and timing functions.              */

// name="scale-slide"
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.scale-slide-enter-active,
.scale-slide-leave-active {
  position: absolute;
  transition: all 0.85s ease;
}

.scale-slide-enter-from {
  left: -100%;
}

.scale-slide-enter-to {
  left: 0%;
}

.scale-slide-leave-from {
  transform: scale(1);
}

.scale-slide-leave-to {
  transform: scale(0.8);
}

// name="flip" mode="out-in"
.flip-enter-active {
  transition: all 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53); //ease-in-quad
  transform-origin: 50% 50%;
}

.flip-leave-active {
  transform-origin: 50% 50%;
  transition: all 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94); //ease-out-quad
}

.flip-enter-from,
.flip-leave-to {
  transform-origin: 50% 50%;
  transform: scaleY(0) translateZ(0);
  opacity: 0;
}
</style>
