import { render } from "./PageContainer.vue?vue&type=template&id=d869d5ae"
import script from "./PageContainer.vue?vue&type=script&lang=js"
export * from "./PageContainer.vue?vue&type=script&lang=js"

import "./PageContainer.vue?vue&type=style&index=0&id=d869d5ae&lang=scss"
script.render = render

export default script
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QScrollArea});
